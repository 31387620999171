import { z } from "zod";
import { CategoryTree } from "./categories";
import { countryCodeISOSchema, countryCodeSchema } from "./countries";
import { currencyCodeSchema } from "./currencies";
import { crunchoLanguagesAnalyzerNameSchema } from "./elastic";
import { postOptionSchema } from "./eventmanager/liveEvent";
import { Format } from "./format";
import {
	appLanguageCodeSchema,
	reactPhoneInputLanguageCodeSchema,
	REACT_PHONE_INPUT_LANGUAGE_CODES,
	ReactPhoneInputLanguageCode,
} from "./languages";
import { L1, l1Schema } from "./recommendation/properties";
import {
	datatourismeConfigSchema,
	eventimConfigSchema,
	facebookEventConfigSchema,
	forkConfigSchema,
	kaxigConfigSchema,
	reservixConfigSchema,
	songkickConfigSchema,
	ticketcoConfigSchema,
	ticketMasterConfigSchema,
	tripAdvisorConfigSchema,
	viatorConfigSchema,
} from "./scrapers";
import { timeZonesSchema } from "./timezones";

export const longitudeSchema = z.number().min(-180).max(180);

export const latitudeSchema = z.number().min(-90).max(90);

/**
 * Object with lat and lng
 */
export const latLngSchema = z.object({
	lat: latitudeSchema,
	lng: longitudeSchema,
});

export type LatLng = z.infer<typeof latLngSchema>;

const marginBoundsSchema = z.object({
	sw: latLngSchema,
	nw: latLngSchema.optional(),
	se: latLngSchema.optional(),
	ne: latLngSchema,
});
export type MarginBounds = z.infer<typeof marginBoundsSchema>;

export const positionSchema = latLngSchema.merge(
	z.object({ alt: z.number().optional() }),
);
export type Position = z.infer<typeof positionSchema>;

export const BBoxSchema = z.union([
	z.tuple([z.number(), z.number(), z.number(), z.number()]),
	z.tuple([
		z.number(),
		z.number(),
		z.number(),
		z.number(),
		z.number(),
		z.number(),
	]),
]);
export type BBox = z.infer<typeof BBoxSchema>;

export const polygonSchema = z.object({
	type: z.enum(["Polygon", "MultiPolygon"]).default("Polygon"),
	coordinates: z.array(positionSchema).array(),
});
export type Polygon = z.infer<typeof polygonSchema>;

export const areaSchema = z.object({
	name: z.string().describe("The internal name of this area"),
	city: z.string().optional().describe("The city related to this area"),
	label: z.string().describe("The label displayed of this area"),
	marginBounds: marginBoundsSchema.optional(),
	polygon: polygonSchema.describe(
		"The list of coordinates describing the area polygon",
	),
	lat: z.number().optional().describe("Latitude of the center"),
	lng: z.number().optional().describe("Longitude of the center"),
	onlyForCuration: z.boolean().optional(),
});
export type Area = z.infer<typeof areaSchema>;

export const geoPointSchema = z.object({
	type: z.literal("Point").default("Point"),
	coordinates: positionSchema,
	bbox: BBoxSchema.optional(),
});
export type Point = z.infer<typeof geoPointSchema>;

export const MAP_PROVIDERS_OPTIONS = ["leaflet", "google"] as const;
export const ANALYTICS_PROVIDERS_OPTIONS = [
	"simple",
	"google",
	"matomo",
	"none",
] as const;
export const AUTOCOMPLETE_PROVIDERS_OPTIONS = ["defaultAC", "google"] as const;
export const STORAGE_PROVIDERS_OPTIONS = ["proxy", "amplify"] as const;

const mapProviderSchema = z.enum(MAP_PROVIDERS_OPTIONS).default("google");
export const autocompleteProviderSchema = z
	.enum(AUTOCOMPLETE_PROVIDERS_OPTIONS)
	.default("defaultAC");
const analyticsProviderSchema = z
	.enum(ANALYTICS_PROVIDERS_OPTIONS)
	.default("simple");

const storageProviderSchema = z
	.enum(STORAGE_PROVIDERS_OPTIONS)
	.default("amplify");

export const serviceProvidersSchema = z.object({
	analytics: analyticsProviderSchema,
	autocomplete: autocompleteProviderSchema,
	map: mapProviderSchema,
	storage: storageProviderSchema,
});

export type MapProvider = z.infer<typeof mapProviderSchema>;
export type AnalyticsProvider = z.infer<typeof analyticsProviderSchema>;
export type AutoCompleteProvider = z.infer<typeof autocompleteProviderSchema>;
export type StorageProviderType = z.infer<typeof storageProviderSchema>;

export const labelSchema = z.object({
	id: z.string(),
	color: z.string().describe("Hexadecimal color value with hashtag"),
	text: z.string(),
});

export type Label = z.infer<typeof labelSchema>;

export const targetOptionSchema = z.object({
	label: z.string(),
	value: z.string(),
});
export const preferredImageSchema = z.object({
	width: z.number().default(1200),
	height: z.number().default(500),
});

export type TargetOption = z.infer<typeof targetOptionSchema>;

export const defaultLocationSchema = z
	.object({
		coordinates: latLngSchema,
		address: z.string(),
		venue: z.string(),
		city: z.string(),
		country: countryCodeISOSchema.optional(),
	})
	.describe(
		"The default location focused when creating an event through the eventmanager",
	);

export type DefaultLocation = z.infer<typeof defaultLocationSchema>;

export const ContactInformationSchema = z
	.object({
		bcc: z
			.array(z.string().email())
			.optional()
			.describe("A list of mails sent in blind carbon copy mode"),
		cc: z
			.array(z.string().email())
			.optional()
			.describe("A list of mails sent in carbon copy mode"),
		createdEmailAlert: z
			.array(z.string().email())
			.optional()
			.describe("A list of emails to send an mail to in case of event creation"),
		language: appLanguageCodeSchema,
		mail: z.string().email().optional(),
		name: z.string().min(1),
		phone: z.string().optional(),
		phoneFormatted: z.string().optional(),
	})
	.refine(
		(contactInformation) => {
			// we need at least one way to communicate with the client
			if (
				!contactInformation.mail &&
				!contactInformation.phone &&
				!contactInformation.phoneFormatted
			) {
				return false;
			}

			return true;
		},
		{ message: "You need to provide an mail or a phoneNumber" },
	);

export type ContactInformation = z.infer<typeof ContactInformationSchema>;

function ReactPhoneInputCountryCodeToType<T>(schema: z.Schema<T>) {
	const langToSchema: Partial<Record<ReactPhoneInputLanguageCode, z.Schema<T>>> =
		{};
	REACT_PHONE_INPUT_LANGUAGE_CODES.forEach((lang) => {
		langToSchema[lang] = schema;
	});

	return z.object(
		langToSchema as Record<ReactPhoneInputLanguageCode, z.Schema<T>>,
	);
}

/**
 * See https://www.npmjs.com/package/react-phone-input-2 to have more information about each field.
 */
export const phoneFormatSchema = z.object({
	country: reactPhoneInputLanguageCodeSchema.optional(),
	onlyCountries: reactPhoneInputLanguageCodeSchema.array().optional(),
	dialCode: z.boolean().optional(),
	disableCountryCode: z.boolean().optional(),
	disableDropdown: z.boolean().optional(),
	masks: ReactPhoneInputCountryCodeToType(z.string()).partial().optional(),
	placeholder: z.string().optional(),
	enableTerritories: z.boolean().optional(),
});

export type PhoneFormat = z.infer<typeof phoneFormatSchema>;

export const mailConstellationSchema = z.object({
	destination: z.string(),
	mail: z.string().optional(),
});

export type MailConstellation = z.infer<typeof mailConstellationSchema>;

export const eventManagerFeaturesSchema = z.object({
	cityRTE: z.any(),
	consentInformation: z.string(),
	contactInformation: ContactInformationSchema,
	createEventDisclaimer: z.string().min(1).optional(),
	defaultLocation: defaultLocationSchema,
	deleteEventsNumberMonths: z.number().default(24),
	hideDefaultCategoriesInEM: z.boolean().optional(),
	hideFreeTicketsField: z.boolean().optional(),
	hidePublishYourEventButton: z.boolean().optional(),
	hideRSVPField: z.boolean().optional(),
	hideUpcomingImportedEvents: z.boolean().optional(),
	hideVideoField: z.boolean().optional(),
	isConstellation: z.boolean().optional(),
	loginDescription: z.string().optional(),
	pictureDescription: z.string().optional(),
	limitNumberCategory: z.number().int().optional(),
	limitNumberPicture: z.number().int().optional(),
	mailConstellation: mailConstellationSchema.array().optional(),
	notifyNewEvent: z.boolean().optional(),
	partnerApproval: z.string().optional(),
	phoneFormat: phoneFormatSchema.optional(),
	postOptions: postOptionSchema.array(),
	preferredImage: preferredImageSchema.optional(),
	printableEventList: z.boolean().optional().default(false),
	quizEnabled: z.boolean().optional().default(false),
	showFacebookPrefill: z.boolean().optional(),
	showLocalField: z.boolean().optional(),
	targetGroupField: z.boolean().optional().default(false),
	targetOptions: targetOptionSchema.array().optional(),
});

export type EventManagerFeatures = z.infer<typeof eventManagerFeaturesSchema>;

export interface L1DependentValue<T> extends Record<L1, T> {}

export function L1ToType<T>(schema: z.Schema<T>) {
	return z.object({
		events: schema,
		hotels: schema,
		restaurants: schema,
		visit: schema,
	});
}

export function filtersToType<T>(schema: z.Schema<T>) {
	return z.object({
		areaFilter: schema,
		dateFilter: schema,
		venuesFilter: schema,
		organizersFilter: schema,
		onlineOnlyFilter: schema,
		bookableFilter: schema,
		openNowFilter: schema,
		freeFilter: schema,
	});
}

export const SCORING_SCRIPTS = [
	"cruncho_score",
	"cruncho_score_old",
	"rhodes_score",
] as const;

export const MAP_CONTROLS_POSITIONS = [
	"LEFT_BOTTOM",
	"RIGHT_BOTTOM",
	"LEFT_TOP",
	"RIGHT_TOP",
] as const;
export const mapControlsPositionSchema = z.enum(MAP_CONTROLS_POSITIONS);
export type MapControlsPosition = z.infer<typeof mapControlsPositionSchema>;

export const customFlagSchema = z
	.record(countryCodeSchema, countryCodeSchema)
	.optional()
	.describe("Basically replace the key for the value");
export type CustomFlagsType = z.infer<typeof customFlagSchema>;

export const categoryAliasSchema = z
	.record(
		z.string().describe("category slug"),
		z.string().describe("replacement for original displayName"),
	)
	.optional();
export type CategoryAlias = z.infer<typeof categoryAliasSchema>;

export const landingPageCategoryConfigurationSchema = z.object({
	carouselName: z.string().optional(),
	hideInFilters: z.boolean().optional(),
	l2: z.string().array().optional(),
	l3: z.string().array().optional(),
});
/* TODO: Try to make this work and put it back, it would be good
	.and(
		z
			.object({
				l2: z.string().array(),
				l3: z.string().array().optional(),
			})
			.or(
				z.object({
					l2: z.string().array().optional(),
					l3: z.string().array(),
				}),
			),
	); */

export type LandingPageCategoryConfiguration = z.infer<
	typeof landingPageCategoryConfigurationSchema
>;

export const landingPageConfigurationSchema = z.record(
	l1Schema.or(z.enum(["topPicks"])),
	landingPageCategoryConfigurationSchema.array(),
);

export type LandingPageConfiguration = z.infer<
	typeof landingPageConfigurationSchema
>;

export const cityFeaturesSchema = z.object({
	allowCSVDownloadDirectory: z.boolean().optional(),
	availableCCs: countryCodeSchema.array().min(1),
	calibrationName: z.string().min(1).optional(),
	categoryAlias: categoryAliasSchema,
	cardRibbonText: z.string().min(1).optional(),
	clientHostname: z.string().optional(),
	countryCode: countryCodeISOSchema,
	currencyCode: currencyCodeSchema,
	customSponsoredText: z.string().min(1).optional(),
	defaultl2Category: z.record(z.string()).optional(),
	defaultl3Category: z.record(z.string().array()).optional(),
	defaultMapZoom: z.number().optional(),
	defaultMapZoomPolygon: polygonSchema.optional(),
	defaultResultRoute: z.string().min(1).optional(),
	disableDefaultLanguageToBrowser: z.boolean().optional(),
	elasticMatchingAnalyzer: crunchoLanguagesAnalyzerNameSchema.optional(),
	emphasizePublishYourEvent: z.boolean().optional(),
	eventManager: z
		.string()
		.url()
		.optional()
		.transform((x) => (!x ? undefined : x)),
	eventManagerFeatures: eventManagerFeaturesSchema.optional(),
	eventsDetailPageCustomText: z.string().optional(),
	excludeRecosOutsideAreas: z.boolean().optional(),
	extraHeadTag: z
		.string()
		.min(1)
		.optional()
		.describe("Code that should be added to the head of the html source"),
	extraBodyTag: z
		.string()
		.min(1)
		.optional()
		.describe("Code that should be added to the body of the html source"),
	fixedRecosNumber: z
		.number()
		.min(
			1,
			"The number of recommendations per page must be greater than or equal to 1",
		)
		.step(1)
		.default(24)
		.optional(),
	forkTrackingCode: z.string().min(1).optional(),
	gaTrackingCode: z.string().min(1).optional(),
	ga4TrackingCode: z.string().min(1).optional(),
	simpleAnalyticsTrackingDomain: z.string().min(1).optional(),
	simpleAnalyticsIgnoreMetrics: z.string().min(1).optional(),
	greyOutEmptyPills: z.boolean().optional(),

	/**
	 * When provided, a "Help" list item will be added to the side menu of tripcruncher.
	 */
	helpLink: z.string().url().optional(),
	hideFilters: filtersToType(z.boolean()).optional(),
	hideBookableCarousel: L1ToType(z.boolean()).optional(),
	hideHandpickedCarousel: z.boolean().optional(),
	hideHotelsCombinedPricesAndLinks: z.boolean().optional(),
	hideMap: z.boolean().optional(),
	hideNonBookableContent: L1ToType(z.boolean()).optional(),
	hideRatingBar: z.boolean().optional(),
	hideRatingDetails: z.boolean().optional(),
	hideRecoDescription: z.boolean().optional(),
	hideSections: z.boolean().optional(),
	hideSidebarLogin: z.boolean().optional(),
	hours12: z.boolean().optional(),
	iframe: z.boolean().optional(),
	iframeResizing: z.boolean().optional(),
	landingPage: landingPageConfigurationSchema.optional(),
	maximumMatchingDistance: z
		.number()
		.optional()
		.describe("Max. distance in meters when matching new content"),
	parentLogin: z.boolean().optional(),
	partnerFooter: z.boolean().optional(),
	prioDescriptionOnCard: z.boolean().optional(),
	quizIframeURL: z.string().min(1).optional(),
	reviewsMaxAge: L1ToType(z.number().min(0).optional())
		.transform((x) => {
			if (x.events || x.hotels || x.restaurants || x.visit) {
				return x;
			}

			return undefined;
		})
		.optional(),
	seeAllIframeURL: z
		.string()
		.url("The URL provided must be valid and begin with http:// or https://")
		.optional(),
	scoringScript: z.enum(SCORING_SCRIPTS),
	scrapers: z
		.object({
			datatourisme: datatourismeConfigSchema.optional(),
			eventim: eventimConfigSchema.optional(),
			facebookEvents: facebookEventConfigSchema.optional(),
			fork: forkConfigSchema.optional(),
			kaxig: kaxigConfigSchema.optional(),
			reservix: reservixConfigSchema.optional(),
			songkick: songkickConfigSchema.optional(),
			ticketco: ticketcoConfigSchema.optional(),
			ticketmaster: ticketMasterConfigSchema.optional(),
			tripadvisor: tripAdvisorConfigSchema.optional(),
			viator: viatorConfigSchema.optional(),
		})
		.optional(),
	serviceProviders: serviceProvidersSchema.optional(),
	showAreaFilter: z.boolean().optional(),
	showAreaFilterOnlyToAdmins: z.boolean().optional(),
	showBookButton: L1ToType(z.boolean()),
	showCityName: z.boolean().optional(),
	showDataDeletionLink: z.boolean().optional(),
	showFormat: z.boolean().optional(),
	showL1Category: L1ToType(z.boolean()),
	showL1CategoryEventsAPI: L1ToType(z.boolean().default(false)).optional(),
	showOnBoardingModal: z.boolean().optional(),
	showOnlyOneDatePickerInMap: z.boolean().optional(),
	showPartnershipWithStockholmFooter: z.boolean().optional(),

	/**
	 * Should we show the price of recommendations?
	 *
	 * Per L1 configuration.
	 */
	showPrices: L1ToType(z.boolean()),
	showTimesOnDatePicker: z.boolean().optional(),
	showVenueName: z.boolean().optional(),
	sponsoredNewDesign: z.boolean().optional(),
	syncFromDestinations: z.string().array().optional(),
	thresholdMinPhotos: z
		.number()
		.int()
		.min(0, "The minimum number of photos must be greater than or equal to 0."),
	thresholdMinReviews: z
		.number()
		.int()
		.min(0, "The minimum number of reviews must be greater than or equal to 0."),
	thresholdMinScore: z
		.number()
		.min(0, "The minimum number score must be greater than or equal to 0.")
		.max(5, "The minimum number score must be less than or equal to 5."),
	timeZone: timeZonesSchema,
	topLeftLogoLinkURL: z
		.string()
		.url("The URL provided must be valid and begin with http:// or https://")
		.optional(),
	translateRecoTitle: z.boolean().default(true).optional(), // Default true as before it was always translating the title
	translateWithDeepL: z.boolean().optional(),

	/**
	 * If none specified, defaults to the languages from your available CCs
	 */
	translationLanguages: countryCodeSchema.array().optional(),
	useBundleLogo: z.boolean().optional(),
	useLearnMoreAsBookButton: z.boolean().optional(),
	useMiles: z.boolean().optional(),
	useUSFlag: z.boolean().optional(),
	useCustomFlags: customFlagSchema,

	weeklyRecoMail: z.boolean().optional(),

	/**
	 * Map controls position (such as zoom, street view, ...).
	 * If not provided, it will default to "RIGHT_BOTTOM"
	 */
	mapControlsPosition: mapControlsPositionSchema.optional(),
});

export type CityFeatures = z.infer<typeof cityFeaturesSchema>;

export const cityGeographySchema = z.object({
	geoCenterPt: geoPointSchema,
	geoNortheastPt: geoPointSchema,
	geoPolygon: polygonSchema,
	geoSouthwestPt: geoPointSchema,
	googleCenterPt: geoPointSchema,
	radius: z.number().optional(),
});

export type CityGeography = z.infer<typeof cityGeographySchema>;

export const destinationCategoriesSchema = z.object({
	l3: z.string().array().describe("Array of l3 slugs"),
	includeDefaults: z
		.boolean()
		.describe("Whether or not to include the default categories"),
	l3ToRemove: z
		.string()
		.array()
		.describe("Array of default l3 slugs to remove")
		.optional(),
});

export type DestinationCategories = z.infer<typeof destinationCategoriesSchema>;

// mongo
export const destinationSchema = z.object({
	_id: z.string(),
	apiKey: z.string().min(1).optional(),
	areas: areaSchema.array(),
	bundle: z.string().optional(),
	categories: destinationCategoriesSchema,
	clientName: z.string(),
	clientType: z.string(),
	customTitle: z.string().optional(),
	favicon: z.string().optional(),
	features: cityFeaturesSchema,
	geography: cityGeographySchema,
	labels: labelSchema.array(),
	name: z.string(),
	subdomainMatch: z.string(),
	timezone: z.string().optional(),
});
export type Destination = z.infer<typeof destinationSchema>;

export type PopulatedDestination = Destination & {
	categoryTree: CategoryTree[];
	formats: Format[];
	venues: string[];
	organizers: string[];
	isRaining: boolean;
};
