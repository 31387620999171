import { z } from "zod";
import { SOULCIRCUS_TRANSLATION_LANGUAGES } from "../languages";
import { dateSchema } from "../utilities";

export const practitionerReviewSchema = z.object({
	content: z.string().max(1200, "Review is too long"),
	date: z.string(),
	name: z.string(),
	rating: z.number(),
	reviewerId: z.string(),
	answer: z.string().max(1200, "Review is too long").optional(),
	answerDate: z.string().optional(),
	slotId: z.string(),
	practitionerId: z.string(),
	_id: z.string().optional(),
});

export type PractitionerReview = z.infer<typeof practitionerReviewSchema>;

export const practitionerFileSchema = z.object({
	credit: z.string().optional(),
	file: z.custom<File>().optional(),
	height: z.number().optional(),
	name: z.string(),
	title: z.string().optional(),
	url: z.string().optional(),
	width: z.number().optional(),
});

export type PractitionerFile = z.infer<typeof practitionerFileSchema>;

export const experienceSectionSchema = z.object({
	description: z
		.string()
		.refine((text) => text.replace(/<[^>]+>/g, "").length <= 400),
	endDate: z.string(),
	city: z.string().optional(),
	country: z.string().optional(),
	place: z.string().optional(),
	startDate: z.string(),
	currentlyInTheRole: z.boolean().optional().default(false),
	title: z.string(),
});

export type ExperienceSection = z.infer<typeof experienceSectionSchema>;

export const certificationsSectionSchema = z.object({
	description: z
		.string()
		.refine((text) => text.replace(/<[^>]+>/g, "").length <= 400),
	endDate: z.string(),
	city: z.string().optional(),
	country: z.string().optional(),
	place: z.string().optional(),
	startDate: z.string(),
	title: z.string(),
});

export type CertificationsSection = z.infer<typeof certificationsSectionSchema>;

export const FAQSectionSchema = z.object({
	question: z.string(),
	answer: z.string(),
});

export type FAQSection = z.infer<typeof FAQSectionSchema>;

export const translationSchema: Record<string, z.ZodType> = {};
SOULCIRCUS_TRANSLATION_LANGUAGES.forEach((lang) => {
	translationSchema[lang] = z
		.object({
			description: z
				.string()
				.refine((text) => text.replace(/<[^>]+>/g, "").length <= 2600),
		})
		.optional();
});

const dynamicTranslationSchema = z.object(translationSchema);

const aboutSectionSchema = dynamicTranslationSchema.extend({
	description: z
		.string()
		.refine((text) => text.replace(/<[^>]+>/g, "").length <= 2600)
		.optional(),
});

export type AboutSection = z.infer<typeof aboutSectionSchema>;

namespace VerifiedStatus {
	export const NOREQUEST = "no_request";
	export const PENDING = "pending";
	export const APPROVED = "approved";
	export const REJECTED = "rejected";
}

export type VERIFIED_STATUS =
	| typeof VerifiedStatus.NOREQUEST
	| typeof VerifiedStatus.PENDING
	| typeof VerifiedStatus.APPROVED
	| typeof VerifiedStatus.REJECTED;

export const VERIFIED_STATUS_ENUM = z.enum([
	VerifiedStatus.NOREQUEST,
	VerifiedStatus.PENDING,
	VerifiedStatus.APPROVED,
	VerifiedStatus.REJECTED,
]);

export const POSSIBLE_VERIFIED_STATUS: readonly VERIFIED_STATUS[] = [
	VerifiedStatus.NOREQUEST,
	VerifiedStatus.PENDING,
	VerifiedStatus.APPROVED,
	VerifiedStatus.REJECTED,
];

export const requirementsSchema = z.object({
	currentlyDue: z.array(z.string()).default([]),
	pastDue: z.array(z.string()).default([]),
});
namespace SubscriptionPlan {
	export const ESSENTIAL = "essential";
	export const PRO = "pro";
	export const WORK = "work";
}

export const videoFileSchema = z.object({
	name: z.string(),
	url: z.string(),
	isUploadedFile: z.boolean().default(true),
});
export type VideoFile = z.infer<typeof videoFileSchema>;

export const SUBSCRIPTION_PLAN_ENUM = z.enum([
	SubscriptionPlan.ESSENTIAL,
	SubscriptionPlan.PRO,
	SubscriptionPlan.WORK,
]);

export const summarySchema = z.object({
	summary: z
		.string()
		.refine((text) => text.replace(/<[^>]+>/g, "").length <= 2600),
});

export const practitionerSchema = z.object({
	userId: z.string(),
	uniqueURL: z
		.string()
		.min(1)
		.regex(/^[a-zA-Z0-9-_]+$/),
	firstVisit: z.boolean().optional(),
	isSponsored: z.boolean().optional(),

	// First type of category : used to select them on carousels on the listing page
	listingCategories: z.string().array().optional(),

	// Second type of category : different types of sickness/status that the practitioner could heal -> shown on the PractitionerMiniCard
	issuesCategories: z.string().array().optional(),

	// Main information :
	firstname: z.string().min(1).optional(),
	lastname: z.string().min(1).optional(),
	type: z.string().optional(),
	summary: z.string().optional(),
	en: summarySchema.optional(),
	sv: summarySchema.optional(),

	// Video file :
	videoFile: videoFileSchema.optional(),

	createdAccountAt: dateSchema.or(z.string()).optional(),

	// Pictures :
	coverPicture: practitionerFileSchema.optional(),
	profilePicture: practitionerFileSchema.optional(),

	// Session section :
	location: z.string().optional(),
	additionalAddress: z.string().optional(),
	city: z.string().optional(),
	country: z.string().optional(),
	languages: z.string().array().optional(),
	numberSessions: z.number().optional(),
	companyName: z.string().optional(),
	email: z.string().email().nullable().optional(),
	phone: z.string().optional(),
	companyNumber: z
		.string()
		.regex(/^\d{10}(\d{2})?$/)
		.nullable()
		.optional(),
	noCompany: z.boolean().default(false).optional(),
	verifiedStatus: VERIFIED_STATUS_ENUM,
	// Stripe
	stripeId: z.string().optional(),
	completedStripeOnboarding: z.boolean().optional(),
	payoutsEnabled: z.boolean().optional(),
	requirements: requirementsSchema.optional(),
	subscriptionStatus: z
		.object({
			subscriptionId: z.string(),
			startDate: dateSchema,
			bookingNumber: z.number(),
			active: z.boolean(),
			customerId: z.string(),
			canceled: z.boolean(),
		})
		.optional(),
	isProfileLive: z.boolean().optional(),

	// Category section :
	categorySection: z
		.object({
			categories: z.string().array(),
		})
		.optional(),

	// Social links section :
	instagramLink: z.string().optional(),
	tiktokLink: z.string().optional(),
	linkedinLink: z.string().optional(),
	youtubeLink: z.string().optional(),
	xLink: z.string().optional(),
	facebookLink: z.string().optional(),
	websiteLink: z.string().optional(),

	// About section :
	aboutSection: aboutSectionSchema.optional(),

	// Gallery section :
	gallerySection: practitionerFileSchema.array().optional(),

	// Experience section :
	experienceSection: experienceSectionSchema.array().optional(),

	// Certifications section :
	certificationsSection: certificationsSectionSchema.array().optional(),

	// Recommended Events section :
	recommendedEventsId: z.string().array().optional(),

	// FAQ section :
	FAQSection: FAQSectionSchema.array().optional(),

	profileViews: z.number().optional(),
});

export type Practitioner = z.infer<typeof practitionerSchema>;

export const practitionerCardSchema = z.object({
	_id: z.string().optional(),
	userId: z.string(),
	isSponsored: z.boolean().optional(),

	// Main information :
	firstname: z.string().min(1).optional(),
	lastname: z.string().min(1).optional(),
	uniqueURL: z
		.string()
		.min(1)
		.regex(/^[a-zA-Z0-9-_]+$/),
	type: z.string().optional(),
	email: z.string().email().nullable().optional(),
	// First type of category : used to select them on carousels on the listing page
	listingCategories: z.string().array().optional(),

	// Second type of category : different types of sickness/status that the practitioner could heal -> shown on the PractitionerMiniCard
	issuesCategories: z.string().array().optional(),

	// Profile picture :
	profilePicture: practitionerFileSchema.optional(),

	// Session section :
	location: z.string().optional(),
	city: z.string().optional(),
	country: z.string().optional(),
	languages: z.string().array().optional(),
	verifiedStatus: VERIFIED_STATUS_ENUM,
	companyName: z.string().optional(),
	companyNumber: z
		.string()
		.regex(/^\d{10}$/)
		.nullable()
		.optional(),
});

/**
 * Type for giving only enough information for the practitioners mini card
 */
export type PractitionerCard = z.infer<typeof practitionerCardSchema>;

export const responsePractitionerByCategorySchema = z.object({
	category: z.string(),
	practitionerList: practitionerCardSchema.array(),
});

export type ResponsePractitionerByCategory = z.infer<
	typeof responsePractitionerByCategorySchema
>;

export type OnboardingRequirements = z.infer<typeof requirementsSchema>;
